<template>
    <div class="ma-3">
        <v-toolbar dense dark flat color="primary">
            <v-btn
                icon
                @click="$router.push('/calidad/evaluaciones')"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>Listado de registros eliminados</v-toolbar-title>
            <v-spacer/>
        </v-toolbar>
        <v-sheet class="pa-2">
            <v-row class="mx-3">
                <v-col md=3 cols=12>
                    <b>Fecha de búsqueda</b>
                    <div class="d-flex flex-row align-center">
                    <page-fecha
                        :fecha.sync="paramsList.fecha"
                        :futuro="false"
                    />
                    {{paramsList.fecha}}
                    </div>
                </v-col>
                <v-col md=3 cols=12>
                    <v-text-field
                        label="Usuario que borra"
                        v-model="paramsList.borra"
                    />
                </v-col>
                <v-col md=3 cols=12>
                    <v-text-field
                        label="Fragmento del original"
                        v-model="paramsList.contenido"
                    />
                </v-col>
                <v-col md=3 cols=12>
                    <v-text-field
                        label="Tabla del registro"
                        v-model="paramsList.registro"
                    />
                </v-col>
            </v-row>
            <div class="d-flex justify-end mx-5">
                <v-btn @click="muestraTodos()" text color="green">Todos</v-btn>
                <v-btn @click="list()" dark color="blue">Buscar</v-btn>
            </div>
        </v-sheet>
        <v-data-table
            elevation="2"
            :headers="headers"
            :items="evalTodas"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loading"

            :server-items-length='evalTotal'
            :options.sync="optionsTable"

            class="elevation-1"
        >

            <template v-slot:[`item.dateCreated`]="{ item }">
                {{moment(item.dateCreated).format('DD-MM-YYYY HH:mm:ss')}}
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon @click="abreDetalles(item)">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <detalles
            :dialogModal.sync="dialogDetalles"
            :estaEval="estaEval"
        />
    </div>
</template>

<script>
export default {
    components:{
        'detalles':()=>import('@/views/admin/borrados/BorradosDetalle'),
        'page-fecha':()=>import('@/components/com/PageFecha'),
    },
    data:()=>({
        dialogDetalles:false,
        headers: [
            { text: 'Fecha', value: 'dateCreated', width:'15%'},
            { text: 'Borrado por', value: 'borra', align:'center', width:'15%'},
            { text: 'Registro', value: 'registro', width:'20%'},

            //{ text: 'Tipo', value: 'tipo',width:'10%'},
            { text: 'Motivo', value: 'motivo'},
            { text: '', value: 'acciones', width:'5%'},
        ],
        paramsList:{},
        evalTodas:[],
        estaEval:{},
        optionsTable:{},
        loading:false,
        evalTotal:0,
    }),
    methods:{
        abreDetalles(id){
            this.estaEval = id
            this.dialogDetalles=true
        },
        async list(){
            this.loading=true
            this.paramsList.start = (this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
            this.paramsList.limit = this.optionsTable.itemsPerPage
            try{
                let eva = await this.$http({
                    // CAMBIAR
                    url:'/admin/listBorrados',
                    method:'GET',
                    params:this.paramsList,
                })
                this.loading=false
                this.evalTodas = eva.data.root
                this.evalTotal = eva.data.total
            }catch(err){
                this.loadingEvalTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        muestraTodos(){
            this.paramsList = {}
            this.optionsTable.page = 1
            this.list()
        },
    },
    watch:{
        optionsTable: {
            handler () {
                this.list()
            },
            deep: true,
        },
        "paramsList.fecha"(){
            this.optionsTable.page = 1
            this.list()
        },
    }
}
</script>

<style>
</style>